import {fonts} from './fonts'

const textFontSize = {
  titleXS: fonts.size.xs,
  titleS: fonts.size.sm,
  titleM: fonts.size.md,
  titleL: fonts.size.lg,
  titleXL: fonts.size.xl,
  title2XL: fonts.size.xxl,
  title3XL: fonts.size['3xl'],
  title4XL: fonts.size['4xl'],
  title5XL: fonts.size['5xl'],
  bodyXS: fonts.size.xs,
  bodyS: fonts.size.sm,
  bodyM: fonts.size.md,
  bodyL: fonts.size.lg,
  labelXS: fonts.size.xs,
  labelS: fonts.size.sm,
  labelM: fonts.size.md
}

type TextFontSize = typeof textFontSize
type TextFontSizeKeys = keyof TextFontSize

const textFontWeight = {
  titleXS: fonts.weight.lg,
  titleS: fonts.weight.lg,
  titleM: fonts.weight.lg,
  titleL: fonts.weight.lg,
  titleXL: fonts.weight.lg,
  title2XL: fonts.weight.lg,
  title3XL: fonts.weight.lg,
  title4XL: fonts.weight.lg,
  title5XL: fonts.weight.lg,
  bodyXS: fonts.weight.md,
  bodyS: fonts.weight.md,
  bodyM: fonts.weight.sm,
  bodyL: fonts.weight.md,
  labelXS: fonts.weight.lg,
  labelS: fonts.weight.lg,
  labelM: fonts.weight.lg
}

const textLineHeight = {
  titleXS: fonts.lineHeight.xxs,
  titleS: fonts.lineHeight.xs,
  titleM: fonts.lineHeight.md,
  titleL: fonts.lineHeight.xl,
  titleXL: fonts.lineHeight.xxl,
  title2XL: fonts.lineHeight['3xl'],
  title3XL: fonts.lineHeight['4xl'],
  title4XL: fonts.lineHeight['5xl'],
  title5XL: fonts.lineHeight['6xl'],
  bodyXS: fonts.lineHeight.xxs,
  bodyS: fonts.lineHeight.sm,
  bodyM: fonts.lineHeight.lg,
  bodyL: fonts.lineHeight.xl,
  labelXS: fonts.lineHeight.xxs,
  labelS: fonts.lineHeight.xs,
  labelM: fonts.lineHeight.md
}

const textLetterSpacing: Partial<TextFontSize> = {
  title4XL: '-1px',
  title5XL: '-1px'
}

const makeText = (type: TextFontSizeKeys) => {
  const fontSize = textFontSize[type]
  const fontWeight = textFontWeight[type]
  const lineHeight = textLineHeight[type]
  const letterSpacing = textLetterSpacing[type] || 'normal'

  const styles = {
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    fontFamily: fonts.family.default
  }

  return styles
}

export const typography = {
  titleXS: makeText('titleXS'),
  titleS: makeText('titleS'),
  titleM: makeText('titleM'),
  titleL: makeText('titleL'),
  titleXL: makeText('titleXL'),
  title2XL: makeText('title2XL'),
  title3XL: makeText('title3XL'),
  title4XL: makeText('title4XL'),
  title5XL: makeText('title5XL'),
  bodyXS: makeText('bodyXS'),
  bodyS: makeText('bodyS'),
  bodyM: makeText('bodyM'),
  bodyL: makeText('bodyL'),
  labelXS: makeText('labelXS'),
  labelS: makeText('labelS'),
  labelM: makeText('labelM')
}
