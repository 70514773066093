const Circular = `
  "Circular",
  "Helvetica Neue",
  Helvetica,
  Arial,
  "Lucida Grande",
  sans-serif
`

export const fonts = {
  family: {
    default: Circular
  },
  size: {
    base: '62.5%',
    xs: '1.2rem',
    sm: '1.4rem',
    md: '1.6rem',
    lg: '2.0rem',
    xl: '2.4rem',
    xxl: '3.0rem',
    '3xl': '3.8rem',
    '4xl': '4.6rem',
    '5xl': '5.6rem'
  },
  lineHeight: {
    base: '1.15',
    xxs: '1.6rem',
    xs: '2.0rem',
    sm: '2.2rem',
    md: '2.4rem',
    lg: '2.6rem',
    xl: '2.8rem',
    xxl: '3.2rem',
    '3xl': '3.8rem',
    '4xl': '4.6rem',
    '5xl': '5.4rem',
    '6xl': '6.4rem'
  },
  weight: {
    sm: 400 /* regular */,
    md: 450 /* book */,
    lg: 500 /* medium */,
    xl: 700 /* bold */
  }
}
