import {MappedColorNames} from '../../types'

export const nativeColors: Record<MappedColorNames, string> = {
  backgroundBrandC050: '#F5F2FF',
  backgroundBrandC100: '#EAE4FF',
  backgroundBrandC200: '#D2C6FF',
  backgroundBrandC400: '#9A72FF',
  backgroundBrandC500: '#7300FF',
  backgroundBrandC600: '#6700E4',
  backgroundBrandC700: '#4E00AD',
  backgroundBrandC800: '#3E008A',
  backgroundBrandC900: '#270057',
  backgroundInteractiveC050: '#F5F2FF',
  backgroundInteractiveC100: '#EAE4FF',
  backgroundInteractiveC200: '#D2C6FF',
  backgroundInteractiveC400: '#9A72FF',
  backgroundInteractiveC500: '#7300FF',
  backgroundInteractiveC600: '#6700E4',
  backgroundInteractiveC700: '#4E00AD',
  backgroundInteractiveC800: '#3E008A',
  backgroundInteractiveC900: '#270057',
  backgroundInteractiveC950: '#170033',
  backgroundNeutralC000: '#ffffff',
  backgroundNeutralC050: '#FAF8F4',
  backgroundNeutralC100: '#EDEAE7',
  backgroundNeutralC200: '#CCC8C4',
  backgroundNeutralC300: '#B8B3AD',
  backgroundNeutralC400: '#A29E99',
  backgroundNeutralC500: '#76726E',
  backgroundNeutralC600: '#696663',
  backgroundNeutralC800: '#42403C',
  backgroundNeutralC900: '#292724',
  backgroundNeutralC950: '#0F0E0F',
  backgroundNeutralC999: '#000000',
  backgroundSpecialC050: '#F5F2FF',
  backgroundSpecialC100: '#EAE4FF',
  backgroundSpecialC200: '#D2C6FF',
  backgroundSpecialC300: '#B8A1FF',
  backgroundSpecialC400: '#9A72FF',
  backgroundSpecialC500: '#7300FF',
  backgroundSpecialC600: '#6700E4',
  backgroundSpecialC700: '#4E00AD',
  backgroundSpecialC800: '#3E008A',
  backgroundSpecialC900: '#270057',
  backgroundSpecialC950: '#170033',
  backgroundSuccessC050: '#F0F9F1',
  backgroundSuccessC100: '#E1F6E4',
  backgroundSuccessC200: '#B8E7BE',
  backgroundSuccessC300: '#8FD598',
  backgroundSuccessC400: '#52C060',
  backgroundSuccessC500: '#45AC53',
  backgroundSuccessC600: '#419B4F',
  backgroundSuccessC700: '#2C7838',
  backgroundSuccessC800: '#136222',
  backgroundWarningC100: '#FFF4E4',
  backgroundWarningC200: '#FFF1C9',
  backgroundWarningC300: '#FBDE93',
  backgroundWarningC500: '#FFC042',
  backgroundWarningC600: '#F9A406',
  backgroundWarningC800: '#BE6902',
  backgroundDangerC050: '#FFF2F4',
  backgroundDangerC100: '#FFE5E9',
  backgroundDangerC200: '#FFC8D0',
  backgroundDangerC400: '#FF7B92',
  backgroundDangerC500: '#FF3366',
  backgroundDangerC600: '#E22252',
  backgroundDangerC700: '#C6284F',
  backgroundDangerC800: '#A12041',
  backgroundFeaturedC050: '#F0F9F1',
  backgroundFeaturedC100: '#E1F6E4',
  backgroundFeaturedC200: '#B8E7BE',
  backgroundFeaturedC400: '#52C060',
  backgroundFeaturedC500: '#45AC53',
  backgroundFeaturedC600: '#419B4F',
  backgroundFeaturedC700: '#2C7838',
  backgroundFeaturedC800: '#136222',
  backgroundHighlightC100: '#FFF4E4',
  backgroundHighlightC200: '#FFF1C9',
  backgroundHighlightC300: '#FBDE93',
  backgroundHighlightC500: '#FFC042',
  backgroundHighlightC600: '#F9A406',
  backgroundAccentC050: '#FFF2F4',
  backgroundAccentC100: '#FFE5E9',
  backgroundAccentC200: '#FFC8D0',
  backgroundAccentC400: '#FF7B92',
  backgroundAccentC500: '#FF3366',
  backgroundAccentC600: '#E22252',
  backgroundAccentC700: '#C6284F',
  backgroundAccentC800: '#A12041',
  backgroundInverseC500: '#7300FF',
  backgroundInverseC600: '#6700E4',
  backgroundInverseC700: '#4E00AD',
  backgroundInverseC800: '#3E008A',
  backgroundInverseC900: '#270057',
  backgroundInverseC950: '#170033',
  contentBrandC200: '#D2C6FF',
  contentBrandC300: '#B8A1FF',
  contentBrandC400: '#9A72FF',
  contentBrandC500: '#7300FF',
  contentBrandC600: '#6700E4',
  contentBrandC700: '#4E00AD',
  contentBrandC800: '#3E008A',
  contentBrandC900: '#270057',
  contentInteractiveC200: '#D2C6FF',
  contentInteractiveC300: '#B8A1FF',
  contentInteractiveC400: '#9A72FF',
  contentInteractiveC500: '#7300FF',
  contentInteractiveC600: '#6700E4',
  contentInteractiveC700: '#4E00AD',
  contentInteractiveC800: '#3E008A',
  contentInteractiveC900: '#270057',
  contentInteractiveC950: '#170033',
  contentNeutralC000: '#ffffff',
  contentNeutralC050: '#FAF8F4',
  contentNeutralC100: '#EDEAE7',
  contentNeutralC200: '#CCC8C4',
  contentNeutralC300: '#B8B3AD',
  contentNeutralC400: '#A29E99',
  contentNeutralC500: '#76726E',
  contentNeutralC600: '#696663',
  contentNeutralC700: '#505050',
  contentNeutralC800: '#42403C',
  contentNeutralC900: '#292724',
  contentNeutralC950: '#0F0E0F',
  contentNeutralC999: '#000000',
  contentSpecialC050: '#F5F2FF',
  contentSpecialC100: '#EAE4FF',
  contentSpecialC200: '#D2C6FF',
  contentSpecialC300: '#B8A1FF',
  contentSpecialC400: '#9A72FF',
  contentSpecialC500: '#7300FF',
  contentSpecialC600: '#6700E4',
  contentSpecialC700: '#4E00AD',
  contentSpecialC800: '#3E008A',
  contentSpecialC900: '#270057',
  contentSpecialC950: '#170033',
  contentSuccessC050: '#F0F9F1',
  contentSuccessC100: '#E1F6E4',
  contentSuccessC200: '#B8E7BE',
  contentSuccessC300: '#8FD598',
  contentSuccessC400: '#52C060',
  contentSuccessC500: '#45AC53',
  contentSuccessC600: '#419B4F',
  contentSuccessC700: '#2C7838',
  contentSuccessC800: '#136222',
  contentSuccessC900: '#17401F',
  contentWarningC100: '#FFF4E4',
  contentWarningC200: '#FFF1C9',
  contentWarningC300: '#FBDE93',
  contentWarningC400: '#FDD672',
  contentWarningC500: '#FFC042',
  contentWarningC600: '#F9A406',
  contentWarningC700: '#E38902',
  contentWarningC800: '#BE6902',
  contentWarningC900: '#915005',
  contentWarningC950: '#5D3508',
  contentDangerC100: '#FFE5E9',
  contentDangerC500: '#FF3366',
  contentDangerC600: '#E22252',
  contentDangerC700: '#C6284F',
  contentDangerC800: '#A12041',
  contentDangerC950: '#511020',
  contentFeaturedC050: '#F0F9F1',
  contentFeaturedC100: '#E1F6E4',
  contentFeaturedC400: '#52C060',
  contentFeaturedC500: '#45AC53',
  contentFeaturedC600: '#419B4F',
  contentFeaturedC700: '#2C7838',
  contentFeaturedC800: '#136222',
  contentHighlightC100: '#FFF4E4',
  contentHighlightC200: '#FFF1C9',
  contentHighlightC500: '#FFC042',
  contentHighlightC700: '#E38902',
  contentHighlightC800: '#BE6902',
  contentHighlightC900: '#915005',
  contentHighlightC950: '#5D3508',
  contentAccentC100: '#FFE5E9',
  contentAccentC200: '#FFC8D0',
  contentAccentC300: '#FFA6B4',
  contentAccentC400: '#FF7B92',
  contentAccentC500: '#FF3366',
  contentAccentC600: '#E22252',
  contentAccentC700: '#C6284F',
  contentAccentC800: '#A12041',
  contentAccentC900: '#72172E',
  contentAccentC950: '#511020',
  contentInverseC500: '#7300FF',
  contentInverseC600: '#6700E4',
  contentInverseC700: '#4E00AD',
  contentInverseC800: '#3E008A',
  contentInverseC900: '#270057',
  contentInverseC950: '#170033',
  borderBrandC050: '#F5F2FF',
  borderBrandC100: '#EAE4FF',
  borderBrandC200: '#D2C6FF',
  borderBrandC300: '#B8A1FF',
  borderBrandC400: '#9A72FF',
  borderBrandC500: '#7300FF',
  borderBrandC600: '#6700E4',
  borderBrandC700: '#4E00AD',
  borderBrandC800: '#3E008A',
  borderBrandC900: '#270057',
  borderBrandC950: '#170033',
  borderInteractiveC050: '#F5F2FF',
  borderInteractiveC100: '#EAE4FF',
  borderInteractiveC200: '#D2C6FF',
  borderInteractiveC300: '#B8A1FF',
  borderInteractiveC400: '#9A72FF',
  borderInteractiveC500: '#7300FF',
  borderInteractiveC600: '#6700E4',
  borderInteractiveC700: '#4E00AD',
  borderInteractiveC800: '#3E008A',
  borderInteractiveC900: '#270057',
  borderInteractiveC950: '#170033',
  borderNeutralC000: '#ffffff',
  borderNeutralC050: '#FAF8F4',
  borderNeutralC100: '#EDEAE7',
  borderNeutralC200: '#CCC8C4',
  borderNeutralC300: '#B8B3AD',
  borderNeutralC400: '#A29E99',
  borderNeutralC500: '#76726E',
  borderNeutralC600: '#696663',
  borderNeutralC700: '#505050',
  borderNeutralC900: '#292724',
  borderNeutralC950: '#0F0E0F',
  borderNeutralC999: '#000000',
  borderSpecialC100: '#EAE4FF',
  borderSpecialC200: '#D2C6FF',
  borderSpecialC300: '#B8A1FF',
  borderSpecialC400: '#9A72FF',
  borderSpecialC500: '#7300FF',
  borderSpecialC600: '#6700E4',
  borderSpecialC700: '#4E00AD',
  borderSpecialC800: '#4E00AD',
  borderSpecialC900: '#270057',
  borderSpecialC950: '#170033',
  borderSuccessC100: '#E1F6E4',
  borderSuccessC200: '#B8E7BE',
  borderSuccessC300: '#8FD598',
  borderSuccessC700: '#2C7838',
  borderSuccessC800: '#136222',
  borderWarningC700: '#E38902',
  borderDangerC050: '#FFF2F4',
  borderDangerC100: '#FFE5E9',
  borderDangerC200: '#FFC8D0',
  borderDangerC400: '#FF7B92',
  borderDangerC500: '#FF3366',
  borderDangerC600: '#E22252',
  borderDangerC700: '#C6284F',
  borderDangerC800: '#A12041',
  borderFeaturedC100: '#E1F6E4',
  borderFeaturedC300: '#8FD598',
  borderFeaturedC500: '#45AC53',
  borderFeaturedC700: '#2C7838',
  borderFeaturedC800: '#136222',
  borderHighlightC700: '#E38902',
  borderAccentC100: '#FFE5E9',
  borderAccentC200: '#FFC8D0',
  borderAccentC500: '#FF3366',
  borderAccentC600: '#E22252',
  borderAccentC700: '#C6284F',
  borderAccentC800: '#A12041',
  borderInverseC500: '#7300FF',
  borderInverseC600: '#6700E4',
  borderInverseC700: '#4E00AD',
  borderInverseC800: '#3E008A',
  borderInverseC900: '#270057',
  borderInverseC950: '#170033',
  transparent: 'rgba(255, 255, 255, 0)',
  shadow: '#292724',
  overlay: '#0F0E0F'
}
