// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFaceWink = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 2A4.75 4.75 0 0 0 2 6.75v6.5A4.75 4.75 0 0 0 6.75 18h6.5A4.75 4.75 0 0 0 18 13.25v-6.5A4.75 4.75 0 0 0 13.25 2h-6.5ZM3.5 6.75A3.25 3.25 0 0 1 6.75 3.5h6.5a3.25 3.25 0 0 1 3.25 3.25v6.5a3.25 3.25 0 0 1-3.25 3.25h-6.5a3.25 3.25 0 0 1-3.25-3.25v-6.5Zm2.742 4.949a.75.75 0 0 1 1.05.033l.01.009c.013.012.041.037.083.07.085.064.23.161.441.263.42.2 1.121.426 2.174.426 1.052 0 1.754-.225 2.174-.426.211-.102.356-.2.44-.264.043-.032.07-.057.084-.07l.01-.008a.75.75 0 0 1 1.093 1.027l-.551-.509c.551.508.551.509.55.51l-.001.001-.003.004-.008.007-.018.02a2.677 2.677 0 0 1-.245.211c-.161.122-.393.275-.703.423-.623.3-1.547.574-2.822.574-1.275 0-2.199-.275-2.822-.574a4.034 4.034 0 0 1-.703-.424 2.687 2.687 0 0 1-.245-.21l-.018-.02-.008-.007-.003-.004-.001-.001c-.001-.001-.001-.002.55-.51l-.551.508a.75.75 0 0 1 .043-1.06ZM6 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm5.29.77.006-.005-.002.001-.002.003h-.001Zm-.002.003v-.001a.75.75 0 0 1-1.085-1.035l.547.513-.547-.513.002-.002.002-.002.004-.005.011-.011.032-.031.1-.09A2.57 2.57 0 0 1 12 7a2.57 2.57 0 0 1 1.779.717l.01.011.005.005.002.002.001.001s.001.001-.535.503l.536-.502a.75.75 0 0 1-1.086 1.035 1.054 1.054 0 0 0-.17-.123A1.07 1.07 0 0 0 12 8.5a1.07 1.07 0 0 0-.71.271l-.001.001h-.001Z"
    />
  </svg>
)

export default SvgFaceWink
