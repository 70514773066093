// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMug2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 3a.75.75 0 0 0-.75.75V6h-.25A2.75 2.75 0 0 0 2 8.75v2.5A2.75 2.75 0 0 0 4.75 14H5v.25A2.75 2.75 0 0 0 7.75 17h7.5A2.75 2.75 0 0 0 18 14.25V3.75a.75.75 0 0 0-.75-.75H5.75ZM5 12.5v-5h-.25c-.69 0-1.25.56-1.25 1.25v2.5c0 .69.56 1.25 1.25 1.25H5Zm1.5 1.75V4.5h10v9.75c0 .69-.56 1.25-1.25 1.25h-7.5c-.69 0-1.25-.56-1.25-1.25Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgMug2
