// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHairdryer = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.626 2.055a.778.778 0 0 0-.028.005L5.613 3.547A.75.75 0 0 0 5 4.284v4.432a.75.75 0 0 0 .613.737l5.407 1.007.868 6.03v.002A1.75 1.75 0 0 0 13.621 18h1.629A1.75 1.75 0 0 0 17 16.25V9.814c.617-.655 1-1.535 1-2.519v-1.59c0-2.26-2.021-3.974-4.26-3.67a.745.745 0 0 0-.037.006l-.077.014ZM15.5 10.79a3.775 3.775 0 0 1-1.797.17l-.077-.014a.851.851 0 0 1-.028-.005l-1.02-.19.796 5.534a.25.25 0 0 0 .247.216h1.629a.25.25 0 0 0 .25-.25v-5.461Zm-3.66-1.702 1.16.215V3.698l-6.5 1.21v3.184l5.306.988a.766.766 0 0 1 .035.007Zm2.66.4c1.117-.12 2-1.052 2-2.192v-1.59c0-1.14-.883-2.072-2-2.192v5.974ZM2 5.75A.75.75 0 0 1 2.75 5h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 2 5.75Zm0 3A.75.75 0 0 1 2.75 8h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 2 8.75Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgHairdryer
