import {Children, useEffect, useMemo} from 'react'
import React from 'react'

import {BrandThemeName} from '@daedalus/core/src/brand/types'

import {getTheme} from '../themes'

type NestedRecord = {[k: string]: string}
//https://saurabhnativeblog.medium.com/how-to-deep-flatten-object-in-javascript-16bc5a22382
function deepFlattenToObject(obj: NestedRecord, prefix = '') {
  return Object.keys(obj).reduce<Record<string, string>>((acc, k) => {
    const pre = prefix.length ? prefix + '-' : '--'
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, deepFlattenToObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})
}

/**
 * This component will provide a CSS variable theme, optionally scoped down to the children.
 */
export const CSSThemeProvider: React.FC<{
  children: React.ReactNode
  themeName?: BrandThemeName
  isGlobal?: boolean
  noWrapper?: boolean
}> = ({children, themeName = 'vio', isGlobal = false, noWrapper = false}) => {
  const theme = getTheme(themeName)
  const uniqueId = useMemo(
    () => `theme-${themeName}${isGlobal ? '-global' : ''}`,
    [themeName, isGlobal]
  )

  useEffect(() => {
    // Append the theme to the head, but only once
    // No need to remove it after it's added
    if (document.querySelector(`[data-theme="${uniqueId}"`)) {
      return
    }

    const serializedTheme = Object.entries(
      deepFlattenToObject(theme as unknown as NestedRecord)
    )
      .map(([key, value]) => [key.replace('--colors', '-'), value])
      .map(([key, value]) => {
        if (
          key.startsWith('--layout-spacing-') ||
          key.startsWith('--layout-radius-') ||
          key.startsWith('--layout-borderWidth-') ||
          key.startsWith('--layout-maxPageWidth')
        ) {
          return `${key}: ${value}px`
        }
        if (key.startsWith('--shadows-')) {
          return `${key.replace('shadows', 'shadow')}: ${value}`
        }
        return `${key}: ${value}`
      })
      .join(';')

    // Sometimes we want to override the global theme (i.e. multi-brand snapshots).
    // For these purposes we generate a unique id to wrap the children
    const selector = isGlobal ? ':root' : `#${uniqueId} `

    const style = document.createElement('style')
    style.dataset.theme = uniqueId
    style.innerHTML = `${selector} {${serializedTheme}}`
    document.head.appendChild(style)
  }, [uniqueId, isGlobal, theme])

  if (isGlobal) {
    return children as React.ReactElement
  }

  if (noWrapper) {
    const childrenArray = React.Children.toArray(children)
    const [firstElement, ...restElements] = childrenArray
    const firstChild = React.cloneElement(firstElement as React.ReactElement, {
      id: uniqueId
    })
    return <>{Children.map([firstChild, ...restElements], child => child)}</>
  }

  return <div id={uniqueId}>{children}</div>
}
