// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTrampoline = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 8c0-.798.557-1.657 1.753-2.358C6.43 4.952 8.108 4.5 10 4.5c1.893 0 3.57.451 4.747 1.142 1.192.698 1.75 1.554 1.753 2.35v.023c-.007.793-.564 1.646-1.753 2.343-1.178.69-2.854 1.142-4.747 1.142-1.892 0-3.57-.451-4.747-1.142C4.057 9.658 3.5 8.798 3.5 8Zm13 2.945a6.65 6.65 0 0 1-.994.707c-1.273.747-2.936 1.223-4.756 1.327v3.271a.75.75 0 0 1-1.5 0V12.98c-1.82-.104-3.483-.58-4.756-1.327a6.648 6.648 0 0 1-.994-.707v2.305a.75.75 0 0 1-1.5 0V8c0-1.55 1.066-2.815 2.494-3.652C5.94 3.5 7.888 3 10 3s4.06.5 5.506 1.348C16.934 5.185 18 6.45 18 8v5.25a.75.75 0 1 1-1.5 0v-2.305Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgTrampoline
