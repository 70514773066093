// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTemperature = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 3.5c-.69 0-1.25.56-1.25 1.25v4.644a.75.75 0 0 1-.352.636 3.5 3.5 0 1 0 3.705 0 .75.75 0 0 1-.353-.636V4.75c0-.69-.56-1.25-1.25-1.25h-.5ZM6 4.75A2.75 2.75 0 0 1 8.75 2h.5A2.75 2.75 0 0 1 12 4.75V9a5 5 0 1 1-6 0V4.75Zm8-2a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM14.75 5a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM14 8.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM9.75 11a.75.75 0 0 0-1.5 0v2.25a.75.75 0 0 0 1.5 0V11Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgTemperature
