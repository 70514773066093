// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCoatHanger = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.904 3A2.654 2.654 0 0 0 6.25 5.654v.596a.75.75 0 1 0 1.5 0v-.596c0-.637.517-1.154 1.154-1.154 1.003 0 1.528 1.191.853 1.932L8.77 7.514a1.855 1.855 0 0 0-.034.033l-6.22 6.492C1.452 15.152 2.24 17 3.78 17h12.44c1.54 0 2.33-1.848 1.263-2.96l-6.22-6.493a1.76 1.76 0 0 0-.283-.24C12.355 5.599 11.15 3 8.904 3Zm.915 5.585a.25.25 0 0 1 .361 0l6.22 6.492a.25.25 0 0 1-.18.423H3.78a.25.25 0 0 1-.18-.423l6.22-6.492Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgCoatHanger
