// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPatio = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      d="M12.825 12.83a.754.754 0 0 0 .754-.753.763.763 0 0 0-.754-.754h-2.01l-.005-3.891h6.28a.744.744 0 0 0 .724-.563.743.743 0 0 0-.362-.844l-7.034-3.927a.733.733 0 0 0-.734 0L2.65 6.025a.753.753 0 0 0-.362.844.763.763 0 0 0 .734.563h6.28l.007 3.891h-2.01a.754.754 0 0 0-.7 1.043.743.743 0 0 0 .7.464h2.01v4.41a.753.753 0 0 0 .753.753.764.764 0 0 0 .754-.754v-4.41h2.01Zm-2.82-9.235 4.14 2.31h-8.25l4.11-2.31Z"
      fill="#6C6770"
    />
    <path
      d="M17.408 9.415a.754.754 0 0 0-.915.542l-.884 3.425h-1.437a1.818 1.818 0 0 0-1.869 1.778v2.08a.753.753 0 0 0 .754.753.764.764 0 0 0 .753-.754V15.16c0-.15.161-.271.362-.271h1.447l.724 2.551a.773.773 0 0 0 .723.553c.07.01.141.01.211 0a.764.764 0 0 0 .523-.935l-.834-2.902 1.005-3.787a.764.764 0 0 0-.563-.954ZM5.802 13.382H4.365L3.48 9.957a.754.754 0 0 0-.914-.542.764.764 0 0 0-.543.924l1.005 3.787-.834 2.903a.763.763 0 0 0 .523.933h.2a.754.754 0 0 0 .724-.552l.734-2.551h1.447c.2 0 .361.12.361.271v2.08a.763.763 0 0 0 .754.752.754.754 0 0 0 .754-.753V15.13a1.817 1.817 0 0 0-1.89-1.748Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgPatio
