// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHeart = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.905 4.289c-1.693-1.486-4.23-1.802-6.194-.196-2.11 1.728-2.423 4.651-.747 6.717a.61.61 0 0 0 .06.064l6.462 5.985c.237.22.602.22.839 0l6.463-5.985a.625.625 0 0 0 .06-.064c1.67-2.059 1.403-5.004-.755-6.722-1.986-1.581-4.496-1.285-6.188.2Zm-.46 1.278C8.15 4.116 6.052 3.772 4.493 5.048 2.906 6.347 2.688 8.478 3.896 10l6.01 5.566L15.916 10c1.213-1.53 1.013-3.67-.591-4.947-1.598-1.272-3.668-.932-4.96.514a.617.617 0 0 1-.92 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.867 10.889c-1.72-2.12-1.397-5.123.765-6.893 1.985-1.624 4.541-1.33 6.273.128 1.73-1.46 4.26-1.732 6.266-.134l-.078.098c-1.986-1.581-4.496-1.285-6.188.2-1.693-1.485-4.23-1.801-6.194-.195-2.11 1.728-2.423 4.651-.747 6.717a.61.61 0 0 0 .06.064l6.462 5.985c.237.22.602.22.839 0l6.463-5.985a.625.625 0 0 0 .06-.064c1.67-2.059 1.403-5.004-.755-6.722l.078-.098c2.213 1.762 2.487 4.787.774 6.899a.737.737 0 0 1-.073.077L10.41 16.95a.742.742 0 0 1-1.009 0L2.94 10.966a.743.743 0 0 1-.072-.077Zm12.458-5.836c-1.598-1.272-3.668-.932-4.96.514a.617.617 0 0 1-.92 0C8.15 4.116 6.052 3.772 4.493 5.048 2.906 6.347 2.688 8.478 3.896 10l6.01 5.566L15.916 10c1.213-1.53 1.013-3.67-.591-4.947Zm.499 4.862c1.164-1.476.97-3.532-.577-4.764-1.54-1.226-3.535-.904-4.788.5a.742.742 0 0 1-1.107 0c-1.258-1.41-3.28-1.734-4.78-.506-1.533 1.254-1.742 3.303-.584 4.77l5.918 5.48 5.918-5.48Z"
    />
  </svg>
)

export default SvgHeart
