// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSearchAi = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M8.635 4.032a.602.602 0 0 0-.627-.573c-3.513.198-6.198 3.238-5.997 6.789.2 3.55 3.212 6.268 6.725 6.07a6.293 6.293 0 0 0 3.405-1.233l3.034 2.738c.282.254.713.23.964-.054a.695.695 0 0 0-.055-.974l-2.93-2.645a6.447 6.447 0 0 0 1.538-3.425c.05-.397-.287-.718-.686-.695-.357.02-.634.31-.69.663-.36 2.298-2.265 4.111-4.658 4.246-2.76.157-5.126-1.978-5.284-4.768-.158-2.79 1.952-5.178 4.712-5.335a.593.593 0 0 0 .56-.613l-.01-.19Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.406 2.281a.281.281 0 0 0-.562 0c0 .222-.109.458-.294.644-.186.185-.422.294-.644.294a.281.281 0 0 0 0 .562c.222 0 .458.109.644.294.185.186.294.422.294.644a.281.281 0 1 0 .562 0 .91.91 0 0 1 .227-.57l.14-.141a.91.91 0 0 1 .57-.227.281.281 0 0 0 0-.562.91.91 0 0 1-.57-.227l-.14-.14a.91.91 0 0 1-.227-.57Zm4.47.11A.383.383 0 0 0 15.5 2a.383.383 0 0 0-.375.39c0 .655-.17 1.252-.471 1.675-.293.412-.716.67-1.28.67a.383.383 0 0 0-.374.39c0 .216.168.391.375.391.563 0 .986.258 1.279.67.3.423.47 1.02.47 1.674 0 .216.169.391.376.391a.383.383 0 0 0 .375-.39c0-.655.17-1.252.471-1.675.294-.412.716-.67 1.28-.67a.383.383 0 0 0 .374-.39.383.383 0 0 0-.375-.391c-.563 0-.985-.258-1.279-.67-.3-.423-.47-1.02-.47-1.674Zm-.383 1.743.007-.014.007.014-.007-.007-.007.007Z"
    />
  </svg>
)

export default SvgSearchAi
