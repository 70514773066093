// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgToiletRoll = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.34 4.682C3.836 5.496 3.5 6.667 3.5 8s.336 2.504.84 3.318C4.85 12.143 5.458 12.5 6 12.5h5.332a5.296 5.296 0 0 1-.268-.393C10.392 11.021 10 9.567 10 8c0-1.567.392-3.02 1.064-4.107a5.3 5.3 0 0 1 .268-.393H6c-.542 0-1.15.357-1.66 1.182ZM8.5 14H14v-1.5c.542 0 1.15-.357 1.66-1.182.504-.814.84-1.985.84-3.318v8.5h-8V14ZM7 14v3.25c0 .414.336.75.75.75h9.5a.75.75 0 0 0 .75-.75V8a.75.75 0 0 0-1.5 0c0-1.333-.336-2.504-.84-3.318C15.15 3.857 14.542 3.5 14 3.5A.75.75 0 0 0 14 2H6c-1.253 0-2.27.818-2.936 1.893C2.392 4.98 2 6.433 2 8c0 1.567.392 3.02 1.064 4.107C3.73 13.182 4.747 14 6 14h1Zm7-8V3.5c-.542 0-1.15.357-1.66 1.182-.504.814-.84 1.985-.84 3.318s.336 2.504.84 3.318c.51.825 1.118 1.182 1.66 1.182V10a.82.82 0 0 0 .587-.251c.097-.1.155-.207.188-.277.068-.143.11-.303.137-.44C14.97 8.742 15 8.378 15 8s-.03-.743-.088-1.03a1.935 1.935 0 0 0-.137-.442 1.01 1.01 0 0 0-.188-.277.82.82 0 0 0-.587-.25Zm0 0a.82.82 0 0 0-.587.251 1.01 1.01 0 0 0-.188.277c-.068.143-.11.303-.137.441A5.468 5.468 0 0 0 13 8c0 .38.03.743.088 1.031.027.138.069.298.137.441.033.07.09.177.188.277A.82.82 0 0 0 14 10V6Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgToiletRoll
